import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Box } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  name: yup
    .string("Enter the storage name")
    .required("Storage name is required"),
  symbol: yup
    .string("Enter the storage symbol")
    .required("Storage symbol is required"),
  temperature: yup
    .number("Enter the temperature")
    .required("Temperature is required"),
});

const StorageForm = () => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    symbol: "",
    temperature: "",
  });

  useEffect(() => {
    if (id !== "new" && id) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/storage/${id}`)
        .then((response) => {
          const { name, symbol, temperature } = response.data.data.storage;
          setInitialValues({ name, symbol, temperature });
        })
        .catch((error) => {
          console.error("Error fetching storage:", error);
          toast.error(`Error: ${error?.response?.data}`);
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        symbol: values.symbol,
        temperature: values.temperature,
        id: id && id !== "new" ? id : undefined,
      };

      const method = id && id !== "new" ? "PUT" : "POST";
      const url = `${process.env.REACT_APP_API_URL}/admin/storage`;

      axios({
        method,
        url,
        data: payload,
      })
        .then((response) => {
          toast.success(
            id
              ? "Storage updated successfully!"
              : "Storage created successfully!",
          );
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(`Error: ${error?.response?.data}`);
        });
    },
  });

  return (
    <div>
      <h1>{id ? "Edit Storage" : "Create Storage"}</h1>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={3}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Storage Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            id="symbol"
            name="symbol"
            label="Storage Symbol"
            value={formik.values.symbol}
            onChange={formik.handleChange}
            error={formik.touched.symbol && Boolean(formik.errors.symbol)}
            helperText={formik.touched.symbol && formik.errors.symbol}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            id="temperature"
            name="temperature"
            label="Temperature"
            type="number"
            value={formik.values.temperature}
            onChange={formik.handleChange}
            error={
              formik.touched.temperature && Boolean(formik.errors.temperature)
            }
            helperText={formik.touched.temperature && formik.errors.temperature}
          />
        </Box>

        <Button color="primary" variant="contained" fullWidth type="submit">
          { id && id !== "new" ? "Update" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default StorageForm;
