import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const ProductTypeList = () => {
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/admin/product-type`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setProductTypes(response.data.data.productTypes);
      })
      .catch((error) => {
        console.error("Error fetching product types:", error);
        toast.error(`Error : ${error?.response?.data}`);
      });
  }, []);

  return (
    <div>
      <h1>Product Type List (Sub sub category)</h1>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "16px" }}
        component={Link}
        to="/product-type/new"
      >
        Add Category
      </Button>
      <Paper elevation={3}>
        {productTypes && productTypes?.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {productTypes.map((productType) => (
                <TableRow key={productType.id}>
                  <TableCell>
                    {/* Check if there's an image, if not use a placeholder */}
                    <Avatar
                      src={
                        productType.image || "https://via.placeholder.com/40"
                      }
                      alt={productType.name}
                    />
                  </TableCell>
                  <TableCell>{productType.name}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      to={`/product-type/${productType.id}`}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          "Not Data Found"
        )}
      </Paper>
    </div>
  );
};

export default ProductTypeList;
