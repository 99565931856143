import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  TextField,
  Grid,
  Pagination,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import PaymentModal from "./PaymentModal";
import { formatDateToReadable } from "../../utils/time";
import { Link } from "react-router-dom";
import ChangeOrderStatusModal from "./ChangeOrderStatusModal";

const ORDER_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  DELIVERED: "delivered",
  CANCELED: "canceled",
  ON_THE_WAY: "on_the_way",
  OUT_FOR_DELIVERY: "out_for_delivery",
};

const PAYMENT_STATUS = {
  PENDING: "pending",
  PAID: "paid",
  FAILED: "failed",
  REFUNDED: "refunded",
  PARTIALLY_PAID: "partially_paid",
  UNPAID: "unpaid",
  CANCELED: "canceled",
};
const OrderList = () => {
  const [orders, setOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderStatus, setOrderStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false);
  const [selectedOrderForStatus, setSelectedOrderForStatus] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/orders`,
          {
            params: {
              page_no: pageNo,
              page_size: pageSize,
              order_status: orderStatus,
              payment_status: paymentStatus,
            },
          },
        );
        setOrders(response.data.data.orders);
      } catch (error) {
        console.error("Error fetching orders:", error);
        toast.error(`Error: ${error?.response?.data}`);
      }
    };

    fetchOrders();
  }, [pageNo, pageSize, orderStatus, paymentStatus]);

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedOrder(null);
  };
  const handleOpenChangeStatusModal = (order) => {
    setSelectedOrderForStatus(order);
    setChangeStatusModalOpen(true);
  };

  const handleCloseChangeStatusModal = () => {
    setChangeStatusModalOpen(false);
    setSelectedOrderForStatus(null);
  };

  const handlePaymentSubmit = (paymentData) => {
    const payload = { ...paymentData, order_id: selectedOrder.id };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/orders/record-payment`,
        payload,
      )
      .then((response) => {
        toast.success("Payment recorded successfully");
        handleCloseModal();
      })
      .catch((error) => {
        toast.error(`Error: ${error?.response?.data}`);
      });
  };

  const renderStatusOptions = (statusObject) => {
    return Object.entries(statusObject).map(([key, value]) => (
      <MenuItem key={key} value={value}>
        {value}
      </MenuItem>
    ));
  };

  return (
    <div>
      <h1>Order List</h1>
      <div style={{ marginBottom: "16px" }}>
        <Grid container spacing={2}>
          <Grid item xs={2} mb={3}>
            <TextField
              fullWidth
              id="order_status"
              name="order_status"
              label="Order Status"
              select
              value={orderStatus}
              onChange={(e) => setOrderStatus(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {renderStatusOptions(ORDER_STATUS)}
            </TextField>
          </Grid>
          <Grid item xs={2} mb={3}>
            <TextField
              label="Payment Status"
              fullWidth
              select
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
            >
              <MenuItem value="">All</MenuItem>
              {renderStatusOptions(PAYMENT_STATUS)}
            </TextField>
          </Grid>
        </Grid>
      </div>
      <Paper elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order Number</TableCell>
              <TableCell>Restaurant</TableCell>
              <TableCell>Order Status</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Rider Name</TableCell>
              <TableCell>Order Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.id}</TableCell>
                <TableCell>{order.restaurant_id}</TableCell>
                <TableCell>{order.order_status}</TableCell>
                <TableCell>{order.payment_status}</TableCell>
                <TableCell></TableCell>{" "}
                {/* Rider Name - assuming data not available */}
                <TableCell>{formatDateToReadable(order.order_date)}</TableCell>
                {order.order_status === "pending" && (
                  <TableCell>
                    <Button onClick={() => handleOpenModal(order)}>
                      Mark Payment
                    </Button>
                    <Button sx={{ml:3}} onClick={() => handleOpenChangeStatusModal(order)}>
                      Change Status
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Pagination
        sx={{ mt: 5 }}
        count={10}
        page={1}
        onChange={() => false}
        color="primary"
      />
      {selectedOrderForStatus && (
        <ChangeOrderStatusModal
          open={changeStatusModalOpen}
          handleClose={handleCloseChangeStatusModal}
          orderId={selectedOrderForStatus.id}
        />
      )}
      {selectedOrder && (
        <PaymentModal
          open={modalOpen}
          handleClose={handleCloseModal}
          handleSubmit={handlePaymentSubmit}
          totalAmount={parseFloat(selectedOrder.total_price)}
        />
      )}
    </div>
  );
};

export default OrderList;
