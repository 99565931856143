import React, { useState, useEffect } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import CreditModal from "./CreditModal";

const OutletList = () => {
  const [outlets, setOutlets] = useState([]);
  const [creditModalOpen, setCreditModalOpen] = useState(false);
  const [selectedOutletId, setSelectedOutletId] = useState(null);

  const handleOpenCreditModal = (outletId) => {
    setSelectedOutletId(outletId);
    setCreditModalOpen(true);
  };

  const handleCloseCreditModal = () => {
    setCreditModalOpen(false);
    setSelectedOutletId(null);
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/restaurant/`)
      .then((response) => {
        setOutlets(response.data.data.restaurants);
      })
      .catch((error) => {
        console.error("Error fetching outlets:", error);
        toast.error(`Error: ${error?.response?.data}`);
      });
  }, []);

  const renderSecondaryRestaurants = (secondaryRestaurants) => {
    return secondaryRestaurants.map((restaurant, index) => (
      <span key={restaurant.id}>
        <Link to={`/outlet/${restaurant.id}`}>{restaurant.name}</Link>
        {index < secondaryRestaurants.length - 1 ? ", " : ""}
      </span>
    ));
  };

  return (
    <div>
      <h1>Outlet List</h1>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "16px" }}
        component={Link}
        to="/outlet/new"
      >
        Add Outlet
      </Button>

      <Paper elevation={3} style={{ marginTop: "16px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Secondary Restaurants</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outlets.map((outlet) => (
              <TableRow key={outlet.id}>
                <TableCell>{outlet.name}</TableCell>
                <TableCell>
                  {outlet.address}, {outlet.floor}, {outlet.pincode}
                </TableCell>
                <TableCell>
                  {outlet.secondaryRestaurants
                    ? renderSecondaryRestaurants(outlet.secondaryRestaurants)
                    : "None"}
                </TableCell>
                <TableCell>
                  <Button
                    component={Link}
                    to={`/outlet/${outlet.id}`}
                    variant="contained"
                    color="primary"
                  >
                    Edit
                  </Button>
                  <Button
                      component={Link}
                      to={`/outlet/${outlet.id}/transactions`}
                      variant="contained"
                      color="primary"
                      sx={{ml:2}}
                  >
                    Transactions
                  </Button>
                  <Button
                      onClick={() => handleOpenCreditModal(outlet.id)}
                      variant="contained"
                      color="secondary"
                      sx={{ml:2}}
                  >
                    Add Credit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <CreditModal
          open={creditModalOpen}
          handleClose={handleCloseCreditModal}
          restaurantId={selectedOutletId}
      />
    </div>
  );
};

export default OutletList;
