// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark', // This enables the dark mode
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          padding: 0,
        },
        'h1, h2, h3, h4, h5, h6, a': {
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
  },
});

export default theme;

