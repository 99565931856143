import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import CategoryList from "./components/Category/CategoryList";
// import SubCategoryList from './components/SubCategory/SubCategoryList';
// import ProductList from './components/Product/ProductList';
import CategoryForm from "./components/Category/CategoryForm";
import Sidebar from "./components/Sidebar";
import { Container, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./styles/theme";
import { Toaster } from "react-hot-toast"; // Import the Toaster component
import SubCategoryList from "./components/SubCategory/SubCategoryList";
import SubCategoryForm from "./components/SubCategory/SubCategoryForm";
import ProductsList from "./components/Product/ProductList";
import ProductForm from "./components/Product/ProductForm";
import ProductTypeList from "./components/ProductType/ProductTypeList";
import ProductTypeForm from "./components/ProductType/ProductTypeForm";
import ProductBulkAddForm from "./components/Product/BulkForm";
import UnitList from "./components/Units/UnitList";
import UnitForm from "./components/Units/UnitForm";
import Login from "./components/Login";
import StorageList from "./components/Storage/StorageList";
import StorageForm from "./components/Storage/StorageForm";
import OutletInfoForm from "./components/Outlet/OutletForm";
import OutletList from "./components/Outlet/OutletList";
import UserList from "./components/Users/UserList";
import UserForm from "./components/Users/UserForm";
import OrderList from "./components/Order/OrderList";
import TransactionList from "./components/Outlet/Transaction/TransactionsList";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("isLoggedIn") === "true" ||
      process.env.NODE_ENV === "development",
  );

  const handleLogin = (status) => {
    setIsAuthenticated(status);
    if (!status) {
      localStorage.removeItem("isLoggedIn"); // Remove login status from localStorage when logging out
    }
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster />

        {!isAuthenticated ? (
          <Login onLogin={handleLogin} />
        ) : (
          <Container maxWidth={false} disableGutters>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Sidebar />
              </Grid>
              <Grid item xs={8}>
                <Routes>
                  <Route path="/categories" element={<CategoryList />} />
                  <Route path="/category/:id" element={<CategoryForm />} />
                  <Route path="/subcategories" element={<SubCategoryList />} />
                  <Route
                    path="/subcategory/:id"
                    element={<SubCategoryForm />}
                  />
                  <Route path="/product-type" element={<ProductTypeList />} />
                  <Route
                    path="/product-type/:id"
                    element={<ProductTypeForm />}
                  />
                  <Route path="/products" element={<ProductsList />} />
                  <Route path="/product/:id" element={<ProductForm />} />
                  <Route path="/unit" element={<UnitList />} />
                  <Route path="/unit/:id" element={<UnitForm />} />
                  <Route path="/storage" element={<StorageList />} />
                  <Route path="/storage/:id" element={<StorageForm />} />
                  <Route path="/outlet/:id" element={<OutletInfoForm />} />
                  <Route path="/outlet/" element={<OutletList />} />
                  <Route path="/users/" element={<UserList />} />
                  <Route path="/users/:id" element={<UserForm />} />
                  <Route path="/order/" element={<OrderList />} />
                  <Route path="/outlet/:id/transactions" element={<TransactionList />} />
                  {/* <Route path="*" element={} /> */}
                </Routes>
              </Grid>
            </Grid>
          </Container>
        )}
      </ThemeProvider>
    </>
  );
}

export default App;
