import React from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const PaymentModalSchema = Yup.object().shape({
  payment_mode: Yup.string().required("Payment mode is required"),
  amount: Yup.number()
    .required("Amount is required")
    .positive("Amount must be positive"),
  transaction_id: Yup.string().required("Transaction Id is required"),
});

const PaymentModal = ({ open, handleClose, handleSubmit, totalAmount }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          Record Payment
        </Typography>
        <Formik
          initialValues={{ payment_mode: "", amount: "", transaction_id: "" }}
          validationSchema={PaymentModalSchema}
          onSubmit={(values, { setSubmitting }) => {
            if (values.amount <= totalAmount) {
              handleSubmit(values);
              setSubmitting(false);
            } else {
              toast.error(
                `Amount cannot exceed total price of the order ${totalAmount}`,
              );
              setSubmitting(false);
            }
          }}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <Field
                as={TextField}
                name="payment_mode"
                select
                label="Payment Mode"
                fullWidth
                margin="normal"
                helperText={touched.payment_mode && errors.payment_mode}
              >
                <MenuItem value="Cash">Cash</MenuItem>
                <MenuItem value="UPI">UPI</MenuItem>
                <MenuItem value="NEFTIMPS">NEFT/IMPS</MenuItem>
              </Field>
              <Field
                as={TextField}
                type="number"
                name="amount"
                label="Amount"
                fullWidth
                margin="normal"
                helperText={touched.amount && errors.amount}
              />
              <Field
                as={TextField}
                type="text"
                name="transaction_id"
                label="Transaction Id"
                fullWidth
                margin="normal"
                helperText={touched.transaction_id && errors.transaction_id}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "16px" }}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default PaymentModal;
