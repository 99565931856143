import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const StorageList = () => {
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/storage`);
        setStorages(response.data.data.storage);
      } catch (error) {
        console.error(error);
        toast.error(`Error: ${error?.response?.data}`);
      }
    };
    fetchData();
  }, []);

  return (
      <div>
        <h1>Storage List</h1>
        <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "16px" }}
            component={Link}
            to="/storage/new"
        >
          Add Storage
        </Button>
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Temperature</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {storages.map((storage) => (
                  <TableRow key={storage.id}>
                    <TableCell>{storage.name}</TableCell>
                    <TableCell>{storage.temperature}</TableCell>
                    <TableCell>
                      <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={`/storage/${storage.id}`}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
  );
};

export default StorageList;
