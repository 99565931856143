import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ConfirmDialog from "../Shared/ConfirmDialog";

const CategoryList = () => {
  const [categories, setCategories] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_API_URL}/admin/category`,
          headers: {},
        };
        const response = await axios.request(config);
        setCategories(response.data.data.categories);
      } catch (error) {
        console.error(error);
        toast.error(`Error : ${error?.response?.data}`);
      }
    };
    fetchData();
  }, []);

  const handleConfirmDelete = async (categoryId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/category/delete`,
        { id: categoryId },
      );
      setCategories(categories.filter((c) => c.id !== categoryId));
      setConfirmDialogOpen(false);
      toast.success("Category successfully deleted");
    } catch (error) {
      console.error("Error deleting category: ", error);
      toast.error(`Error : ${error?.response?.data}`);
    }
  };

  const handleDeleteClick = (category) => {
    setSelectedCategory(category);
    setConfirmDialogOpen(true);
  };

  return (
    <div>
      <h1>Category List</h1>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "16px" }}
        component={Link}
        to="/category/new"
      >
        Add Category
      </Button>
      <Paper elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow
                key={category.id}
                style={{
                  backgroundColor: category.deleted ? "#929292" : "transparent",
                  opacity: category.deleted ? 0.5 : 1,
                }}
              >
                <TableCell>
                  <Avatar src={category.image.url} alt={category.name} />
                </TableCell>
                <TableCell>{category.name}</TableCell>
                <TableCell>
                  {!category.deleted && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/category/${category.id}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteClick(category)}
                        style={{ marginLeft: "10px" }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </Paper>
      <ConfirmDialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={() => handleConfirmDelete(selectedCategory.id)}
          item={selectedCategory}
      />
    </div>
  );
};

export default CategoryList;
