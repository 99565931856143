import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  name: yup
    .string("Enter a category name")
    .required("Category name is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "File too large",
      (value) =>
        typeof value === "string" || // Bypass size check if value is a string (URL)
        !value ||
        (value && value.size <= 1048576), // 1MB size limit
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        typeof value === "string" || // Bypass format check if value is a string (URL)
        !value ||
        (value && ["image/jpeg", "image/png"].includes(value.type)),
    ),
  sub_category_id: yup
    .string("Select a sub-category")
    .required("Sub-category is required"),
});

const ProductTypeForm = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    image: undefined,
    sub_category_id: "",
  });

  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    const category = categories.find((cat) => cat.id === categoryId);
    if (category) {
      setSubCategories(category.sub_categories || []);
    } else {
      setSubCategories([]);
    }
    formik.setFieldValue("category_id", categoryId);
  };

  // API call to fetch the categories
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/admin/category`,
      method: "GET",
      headers: {
        "X-API-Key": "{{token}}",
      },
    })
      .then((response) => {
        setCategories(response.data.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        toast.error(`Error : ${error?.response?.data}`);
      });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const form = new FormData();
      form.append("name", values.name);
      if (values.image instanceof File) {
        form.append("image", values.image);
      }
      form.append("sub_category_id", values.sub_category_id);
      if (id && id !== "new") {
        form.append("id", id);
      }
      const url = `${process.env.REACT_APP_API_URL}/admin/product-type`;
      const method = id ? "PUT" : "POST";

      axios({
        url,
        method,
        headers: {
          "X-API-Key": "{{token}}",
          "Content-Type": "multipart/form-data",
        },
        data: form,
      })
        .then((response) => {
          toast.success(
            id
              ? "Product type updated successfully!"
              : "Product type created successfully!",
          );
        })
        .catch((error) => {
          toast.error(`Error : ${error?.response?.data}`);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (id && id !== "new") {
      axios({
        url: `${process.env.REACT_APP_API_URL}/admin/product-type/${id}`,
        method: "GET",
        headers: {
          "X-API-Key": "{{token}}",
        },
      })
        .then((response) => {
          const { name, sub_category_id, image } =
            response.data.data.productType;
          setInitialValues({
            name,
            sub_category_id,
            image: image.url, // Assuming image URL is returned
          });
          setImagePreview(image.url);
          // Set the sub-categories based on the fetched category
          const category = categories.find((cat) =>
            cat.sub_categories.some((subCat) => subCat.id === sub_category_id),
          );
          if (category) {
            setSubCategories(category.sub_categories || []);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(`Error : ${error?.response?.data}`);
        });
    }
  }, [id, categories]);

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("image", file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  return (
    <div>
      <h1>{id ? "Edit Product Type" : "Create Product Type"}</h1>

      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        {/* Category Dropdown */}
        <Box mb={3}>
          <InputLabel id="category-label">Category</InputLabel>
          <FormControl
            fullWidth
            error={formik.touched.category && Boolean(formik.errors.category)}
          >
            <Select
              labelId="category-label"
              id="category_id"
              name="category_id"
              value={formik.values.category_id}
              onChange={handleCategoryChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="">
                <em>Select a category</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.category_id && formik.errors.category_id ? (
              <FormHelperText>{formik.errors.category_id}</FormHelperText>
            ) : null}
          </FormControl>
        </Box>

        {/* Sub-category Dropdown (appears based on category selection) */}
        {subCategories.length > 0 && (
          <Box mb={3}>
            <InputLabel id="sub-category-label">Sub-Category</InputLabel>
            <FormControl fullWidth>
              <Select
                labelId="sub-category-label"
                id="sub_category_id"
                name="sub_category_id"
                value={formik.values.sub_category_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="">
                  <em>Select a sub-category</em>
                </MenuItem>
                {subCategories.map((subCategory) => (
                  <MenuItem key={subCategory.id} value={subCategory.id}>
                    {subCategory.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box mb={3}>
          {/* Box component with margin-bottom */}
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Category Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>

        {/* Dropdown for category */}

        <Box mb={3}>
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: "200px", marginBottom: "10px" }}
            />
          )}
          <input
            id="image"
            name="image"
            type="file"
            onChange={handleImageChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.image && formik.errors.image ? (
            <div>{formik.errors.image}</div>
          ) : null}
        </Box>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik.isSubmitting}
        >
          {id ? "Update" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default ProductTypeForm;
