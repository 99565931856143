import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const UnitList = () => {
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/unit`);
        setUnits(response.data.data.units);
      } catch (error) {
        console.error(error);
        toast.error(`Error : ${error?.response?.data}`);
      }
    };
    fetchData();
  }, []);

  return (
      <div>
        <h1>Unit List</h1>
        <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "16px" }}
            component={Link}
            to="/unit/new"
        >
          Add Unit
        </Button>
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Symbol</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {units.map((unit) => (
                  <TableRow key={unit.id}>
                    <TableCell>{unit.symbol}</TableCell>
                    <TableCell>{unit.name}</TableCell>
                    <TableCell>
                      <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={`/unit/${unit.id}`}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
  );
};

export default UnitList;
