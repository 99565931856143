import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Button,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom"; // Assuming you're using react-router-dom
import toast from "react-hot-toast"; // Import the toast function

const validationSchema = yup.object({
  name: yup
    .string("Enter a category name")
    .required("Category name is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "File too large",
      (value) =>
        typeof value === "string" || // Bypass size check if value is a string (URL)
        !value ||
        (value && value.size <= 1048576),
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) =>
        typeof value === "string" || // Bypass format check if value is a string (URL)
        !value ||
        (value && ["image/jpeg", "image/png"].includes(value.type)),
    ),
  category_id: yup // Updated key here
    .string("Select a category")
    .required("Category is required"),
});

const SubCategoryForm = () => {
  const { id } = useParams();
  const [categories, setCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState(null); // State for image preview
  const [initialValues, setInitialValues] = useState({
    name: "",
    image: undefined,
    category_id: "",
  });
  // API call to fetch the categories
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_API_URL}/admin/category`,
      method: "GET",
      headers: {
        "X-API-Key": "{{token}}",
      },
    })
      .then((response) => {
        setCategories(response.data.data.categories);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
        toast.error(`Error : ${error?.response?.data}}`);
      });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const form = new FormData();
      form.append("name", values.name);
      if (values.image instanceof File) {
        form.append("image", values.image);
      }
      form.append("category_id", values.category_id);

      if (id && id !== "new") {
        form.append("id", id);
      }

      const url = `${process.env.REACT_APP_API_URL}/admin/sub-category`;

      const method = id && id !== "new" ? "PUT" : "POST";

      axios({
        url,
        method,
        headers: {
          "X-API-Key": "{{token}}",
          "Content-Type": "multipart/form-data",
        },
        data: form,
      })
        .then((response) => {
          toast.success(
            id
              ? "Sub-category updated successfully!"
              : "Sub-category created successfully!",
          );
        })
        .catch((error) => {
          toast.error(
            id
              ? "Failed to update sub-category!"
              : "Failed to create sub-category!",
          );

          toast.error(`Error: ${error?.response?.data}`);
        });
    },
  });

  useEffect(() => {
    if (id !== "new" && id) {
      axios({
        url: `${process.env.REACT_APP_API_URL}/admin/sub-category/${id}`,
        method: "GET",
        headers: {
          "X-API-Key": "{{token}}",
        },
      })
        .then((response) => {
          const { name, category_id, image } = response.data?.data?.subCategory;
          setInitialValues({
            name,
            category_id,
            image: image.url, // Set image URL
          });
          setImagePreview(image.url);
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(`Error : ${error?.response?.data}`);
        });
    }
  }, [id]);

  // Handle image change and update preview
  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      formik.setFieldValue("image", file);
      setImagePreview(URL.createObjectURL(file)); // Update image preview
    }
  };
  return (
    <div>
      <h1>Sub Category</h1>

      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Box mb={3}>
          <InputLabel id="category-label">Category</InputLabel>
          <FormControl
            fullWidth
            error={
              formik.touched.category_id && Boolean(formik.errors.category_id)
            }
          >
            <Select
              labelId="category-label"
              id="category_id"
              name="category_id" // Update here
              value={formik.values.category_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              displayEmpty
            >
              <MenuItem value="">
                <em>Select a category</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.category_id && formik.errors.category_id ? ( // Update here
              <FormHelperText>{formik.errors.category_id}</FormHelperText> // Update here
            ) : null}
          </FormControl>
        </Box>
        <Box mb={3}>
          {" "}
          {/* Box component with margin-bottom */}
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Category Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>

        {/* Dropdown for category */}

        <Box mb={3}>
          {/* Box component with margin-bottom */}
          {imagePreview && (
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: "200px", marginBottom: "10px" }}
            />
          )}
          <input
            id="image"
            name="image"
            type="file"
            onChange={handleImageChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.image && formik.errors.image ? (
            <div>{formik.errors.image}</div>
          ) : null}
        </Box>

        <Button color="primary" variant="contained" fullWidth type="submit">
          Submit
        </Button>
      </form>
    </div>
  );
};

export default SubCategoryForm;
