import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Box } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  name: yup.string("Enter the unit name").required("Unit name is required"),
  symbol: yup
    .string("Enter the unit symbol")
    .required("Unit symbol is required"),
});

const UnitForm = () => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    symbol: "",
  });

  useEffect(() => {
    if (id !== "new" && id) {
      // Fetch the existing unit data for editing
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/unit/${id}`)
        .then((response) => {
          const { name, symbol } = response.data.data.unit;
          setInitialValues({ name, symbol });
        })
        .catch((error) => {
          console.error("Error fetching unit:", error);
          toast.error(`Error: ${error?.response?.data}`);
        });
    }
  }, [id]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true, // This is important when updating initialValues
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        symbol: values.symbol,
        id: id && id !== "new" ? id : undefined,
      };

      const method = id ? "PUT" : "POST";
      const url = `${process.env.REACT_APP_API_URL}/admin/unit`;

      axios({
        method,
        url,
        data: payload,
      })
        .then((response) => {
          toast.success(
            id ? "Unit updated successfully!" : "Unit created successfully!",
          );
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(`Error: ${error?.response?.data}`);
        });
    },
  });

  return (
    <div>
      <h1>{id ? "Edit Unit" : "Create Unit"}</h1>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={3}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Unit Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>

        <Box mb={3}>
          <TextField
            fullWidth
            id="symbol"
            name="symbol"
            label="Unit Symbol"
            value={formik.values.symbol}
            onChange={formik.handleChange}
            error={formik.touched.symbol && Boolean(formik.errors.symbol)}
            helperText={formik.touched.symbol && formik.errors.symbol}
          />
        </Box>

        <Button color="primary" variant="contained" fullWidth type="submit">
          {id ? "Update" : "Submit"}
        </Button>
      </form>
    </div>
  );
};

export default UnitForm;
