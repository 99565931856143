import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { TextField, Button, Box } from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  name: yup
    .string("Enter a category name")
    .required("Category name is required"),
  image: yup
    .mixed()
    .required("Image is required")
    .test("fileSize", "File too large", (value) =>
      typeof value === "string"
        ? true
        : !value || (value && value.size <= 1048576),
    )
    .test("fileFormat", "Unsupported Format", (value) =>
      typeof value === "string"
        ? true
        : !value || (value && ["image/jpeg", "image/png"].includes(value.type)),
    ),
});

const CategoryForm = () => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({
    name: "",
    image: undefined,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true); // Start submitting
      const form = new FormData();
      form.append("name", values.name);
      if (values.image instanceof File) {
        form.append("image", values.image);
      }

      if (id && id !== "new") {
        form.append("id", id);
      }
      const url = `${process.env.REACT_APP_API_URL}/admin/category`;

      const method = id && id !== "new" ? "PUT" : "POST"; // Choose method based on the presence of id

      axios({
        url,
        method,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: form,
      })
        .then((response) => {
          toast.success(
            id
              ? "Category updated successfully!"
              : "Category created successfully!",
          );
        })
        .catch((error) => {
          toast.error(
            id ? "Failed to update category!" : "Failed to create category!",
          );
          toast.error(`Error : ${error?.response?.data}`);
        })
        .finally(() => {
          setSubmitting(false); // Reset submitting state
        });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (id && id !== "new") {
      axios({
        url: `${process.env.REACT_APP_API_URL}/admin/category/${id}`,
        method: "GET",
      })
        .then((response) => {
          const { name, image } = response.data.data.category;
          console.log("response", response.data);
          setInitialValues({
            name,
            image: image.url, // Handle displaying the existing image
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(`Error : ${error?.response?.data}`);
        });
    }
  }, [id]);

  return (
    <div>
      <h1>{id ? "Edit Category" : "Create Category"}</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Box mb={3}>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Category Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>

        <Box mb={3}>
          {formik.values.image && typeof formik.values.image === "string" && (
            <img
              src={formik.values.image}
              alt="Category"
              style={{ maxWidth: "200px", marginBottom: "10px" }}
            />
          )}
          <input
            id="image"
            name="image"
            type="file"
            onChange={(event) => {
              formik.setFieldValue("image", event.currentTarget.files[0]);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.image && formik.errors.image)}
          />
          {formik.touched.image && formik.errors.image && (
            <div>{formik.errors.image}</div>
          )}
        </Box>

        <Button color="primary" variant="contained" fullWidth type="submit">
          {id ? "Update Category" : "Create Category"}
        </Button>
      </form>
    </div>
  );
};

export default CategoryForm;
