import React, { useState, useEffect } from "react";
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";

const UserList = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        let config = {
            method: "get",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_API_URL}/admin/user`,
            headers: {},
        };

        axios
            .request(config)
            .then((response) => {
                setUsers(response.data.data.users);
            })
            .catch((error) => {
                console.error("Error fetching users:", error);
                toast.error(`Error: ${error?.response?.data}`);
            });
    }, []);

    const tableBodyStyle = {
        maxHeight: "400px", // Adjust the height as needed
        overflowY: "auto",
    };
    return (
        <div>
            <h1>User List</h1>
            <Paper elevation={3}>
                {users && users.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>Mobile No</TableCell>
                                <TableCell>Email ID</TableCell>
                                <TableCell>Access Type</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody style={tableBodyStyle}>
                            {users.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.first_name || 'N/A'}</TableCell>
                                    <TableCell>{user.last_name || 'N/A'}</TableCell>
                                    <TableCell>{user.mobile_no}</TableCell>
                                    <TableCell>{user.email_id || 'N/A'}</TableCell>
                                    <TableCell>{user.access_type || 'N/A'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    "No Data Found"
                )}
            </Paper>
        </div>
    );
};

export default UserList;
