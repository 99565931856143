import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import axios from "axios";
import FormData from "form-data";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { storageTypeList } from "../../const/productConst";
import { Link, useParams } from "react-router-dom";
import ProductBulkAddForm from "./BulkForm";
import toast from "react-hot-toast";
import * as yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"; // import styles

const validationSchema = yup.object({
  name: yup.string().required("Product Name is required"),
  product_details: yup.string().required("Product Details are required"),
  gst: yup.string().required("GST is required"),
  hsn_code: yup.string().required("HSN Code is required"),
  return_window: yup
    .number()
    .required("Return Window is required")
    .positive("Return Window must be a positive number"),
  unit_id: yup.string().required("Unit is required"),
  purchase_price: yup
    .number()
    .required("Purchase Price is required")
    .positive("Purchase Price must be a positive number"),
  min_order_quantity: yup
    .number()
    .required("Minimum Order Quantity is required")
    .positive("Minimum Order Quantity must be a positive number")
    .integer("Minimum Order Quantity must be an integer"),
  moq_sale_price: yup
    .number()
    .required("Minimum Order Price is required")
    .positive("Minimum Order Quantity must be a positive number"),
  storage_type_id: yup.string().required("Storage Type is required"),
  is_veg: yup
    .boolean()
    .required("This field is required")
    .typeError("You must select a value"),
  unit_quantity: yup
    .number()
    .nullable()
    .positive("Unit Quantity must be a positive number")
    .typeError("Unit Quantity must be a number"),
});
function ProductUploadForm() {
  const [categories, setCategories] = useState([]); // State to store fetched categories
  const [subCategories, setSubCategories] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [units, setUnits] = useState([]);
  const [bulks, setBulksData] = useState([]); // State to store the fetched units
  const [productId, setProductId] = useState(""); // State to store the fetched units
  const { id } = useParams(); // Extract productId from URL
  const [productImages, setProductImages] = useState([]); // State to store product images
  const [storages, setStorages] = useState([]);
  const [imageOrder, setImageOrder] = useState([]);
  const formik = useFormik({
    initialValues: {
      category_id: "",
      sub_category_id: "",
      name: "",
      description: "",
      product_details: "",
      brand_name: "",
      purchase_price: "2",
      min_order_quantity: 1,
      moq_sale_price: "",
      hsn_code: "",
      // bulk_sale_price: "",
      // bulk_sale_quantity: "",
      gst: "0",
      return_window: 12,
      unit_id: "",
      discount: "",
      storage_type_id: "",
      is_veg: "",
      priceFormula: 1.4,
      images: [], // Store uploaded images here
      price: 0,
      product_type_id: "",
      unit_quantity: 0,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const data = new FormData();

      for (const key in values) {
        if (key !== "images") {
          data.append(key, values[key]);
        }
      }

      // Convert FileList to array and append images to the FormData instance
      Array.from(values.images).forEach((image, index) => {
        data.append(`images`, image);
      });
      const headers = {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      };

      const method = id && id !== "new" ? axios.put : axios.post;
      try {
        const response = await method(
          `${process.env.REACT_APP_API_URL}/admin/products`,
          id && id !== "new" ? values : data,
          {
            headers: headers,
          },
        );

        formik.setSubmitting(false);
        setProductId(response.data.data?.product?.id);
        toast.success("Added successfully");
      } catch (error) {
        console.error(error);
        toast.error(`Error : ${error?.response?.data}`);
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    // Initialize imageOrder state with the order of productImages
    setImageOrder(productImages.map((img) => img.id));
  }, [productImages]);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(imageOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setImageOrder(items);
  };

  const updateImageSequence = async () => {
    for (let i = 0; i < imageOrder.length; i++) {
      try {
        await axios.put(`/admin/image/update-sequence/${imageOrder[i]}`, {
          sequence: i + 1,
        });
      } catch (error) {
        console.error("Error updating image sequence: ", error);
        toast.error(`Error: ${error?.response?.data}`);
      }
    }
    toast.success("Image sequence updated successfully");
  };
  // Fetch product details from the API and update state
  useEffect(() => {
    if (id && id !== "new") {
      const fetchProductById = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/products/${id}`,
          );
          const productData = response.data.data.product;
          // Calculate the price formula based on the prefilled data
          let calculatedFormula = 1.4; // Default value
          if (productData.purchase_price && productData.moq_sale_price) {
            calculatedFormula =
              productData.moq_sale_price / productData.purchase_price;
          }
          await formik.setValues({
            ...productData,
            storage_type_id: productData.storage_type_id || "",
            category_id: productData.category_id || "",
            sub_category_id: productData.sub_category_id || "",
            purchase_price: parseFloat(productData.purchase_price) || 0,
            priceFormula: calculatedFormula,
          });
          setBulksData(productData.bulks);
          setProductImages(productData.images);
        } catch (error) {
          console.error("Error fetching product by ID:", error);
          toast.error(`Error : ${error?.response?.data}`);
        }
      };
      fetchProductById();
    }
  }, [productId]);

  // Function to delete an image
  const deleteImage = async (imageId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/admin/image/${imageId}`,
      );
      setProductImages((currentImages) =>
          currentImages.filter((img) => img.id !== imageId),
      );
      setImageOrder((currentOrder) =>
          currentOrder.filter((id) => id !== imageId),
      );
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error(`Error : ${error?.response?.data}`);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        // Using axios directly, based on the provided axios usage example
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/category`,
        );
        setCategories(response.data.data.categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error(`Error : ${error?.response?.data}`);
      }
    };

    fetchCategories(); // Call the function to fetch categories
  }, []);

  // Fetch units from the API and update state
  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/unit`,
        );
        setUnits(response.data.data.units);
      } catch (error) {
        console.error("Error fetching units:", error);
        toast.error(`Error : ${error?.response?.data}`);
      }
    };

    fetchUnits(); // Call the function to fetch units
  }, []);

  const handleImageChange = (event) => {
    console.log("event.target.files:", event.target.files); // Add this line
    formik.setFieldValue("images", Array.from(event.target.files));
  };

  const handlePriceChange = (event) => {
    // Update the price value
    formik.handleChange(event);
    // Calculate the purchase price based on the formula
    const newMomSalePrice = event.target.value * formik.values.priceFormula;
    // Update the purchase price in the form values
    formik.setFieldValue(
      "moq_sale_price",
      newMomSalePrice.toFixed(2).toString(),
    );
  };

  // Update on formila change
  useEffect(() => {
    // Calculate the new MOQ Sale Price based on the updated Price Formula
    const newMoqSalePrice =
      formik.values.purchase_price * formik.values.priceFormula;

    // Update the MOQ Sale Price in the form
    formik.setFieldValue(
      "moq_sale_price",
      newMoqSalePrice.toFixed(2).toString(),
    );
  }, [formik.values.priceFormula, formik.values.purchase_price]);

  useEffect(() => {
    const selectedCategory = categories.find(
      (cat) => cat.id === formik.values.category_id,
    );
    if (selectedCategory) {
      setSubCategories(selectedCategory.sub_categories);
    } else {
      setSubCategories([]); // Reset sub-categories if none is selected or found
    }
  }, [formik.values.category_id, categories]);

  // Add a new effect for fetching product types when sub_category_id changes
  useEffect(() => {
    if (formik.values.sub_category_id) {
      const fetchProductTypes = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/admin/sub-category/${formik.values.sub_category_id}`,
          );

          setProductTypes(response.data.data.subCategory.product_types);
        } catch (error) {
          console.error("Error fetching product types:", error);
          toast.error(`Error : ${error?.response?.data}`);
        }
      };

      fetchProductTypes();
    }
  }, [formik.values.sub_category_id]);

  //
  useEffect(() => {
    // Parse values as numbers
    const purchasePrice = parseFloat(formik.values.purchase_price) || 0;
    const priceFormula = parseFloat(formik.values.priceFormula) || 0;

    // Calculate the new MOQ Sale Price
    const newMoqSalePrice = purchasePrice * priceFormula;

    // Check if the calculated price is a valid number
    if (!isNaN(newMoqSalePrice)) {
      formik.setFieldValue(
        "moq_sale_price",
        newMoqSalePrice.toFixed(2).toString(),
      );
    } else {
      // Set a default or fallback value if calculation results in NaN
      formik.setFieldValue("moq_sale_price", "0.00");
    }
  }, [
    formik.values.priceFormula,
    formik.values.purchase_price,
    formik.setFieldValue,
  ]);

  useEffect(() => {
    const fetchStorages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/storage`,
        );
        setStorages(response.data.data.storage);
      } catch (error) {
        console.error("Error fetching storages:", error);
        toast.error(`Error : ${error?.response?.data}`);
      }
    };

    fetchStorages();
  }, []);
  return (
    <Container>
      <Typography variant="h4" mb={5} mt={3}>
        Product Upload
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="name"
                name="name"
                label="Product Name *"
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <ReactQuill
                theme="snow"
                value={formik.values.description}
                onChange={(content) =>
                  formik.setFieldValue("description", content)
                }
              />
              {formik.touched.description && formik.errors.description && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {formik.errors.description}
                </div>
              )}
            </Grid>
            {/*<Grid item xs={12} mb={3}>*/}
            {/*  <TextField*/}
            {/*    fullWidth*/}
            {/*    id="description"*/}
            {/*    name="description"*/}
            {/*    label="Description"*/}
            {/*    multiline*/}
            {/*    rows={4}*/}
            {/*    value={formik.values.description}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="category_id"
                name="category_id"
                label="Category ID"
                select
                value={formik.values.category_id}
                onChange={formik.handleChange}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="sub_category_id"
                name="sub_category_id"
                label="Sub Category"
                select
                value={formik.values.sub_category_id}
                onChange={formik.handleChange}
              >
                {subCategories.map((subCat) => (
                  <MenuItem key={subCat.id} value={subCat.id}>
                    {subCat.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="product_type_id"
                name="product_type_id"
                label="Product Type"
                select
                value={formik.values.product_type_id}
                onChange={formik.handleChange}
              >
                <MenuItem value={null}>None</MenuItem>
                {productTypes?.map((productType) => (
                  <MenuItem key={productType.id} value={productType.id}>
                    {productType.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="brand_name"
                name="brand_name"
                label="Brand Name"
                value={formik.values.brand_name}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="product_details"
                name="product_details"
                label="Product Details *"
                multiline
                rows={4}
                value={formik.values.product_details}
                onChange={formik.handleChange}
                error={
                  formik.touched.product_details &&
                  Boolean(formik.errors.product_details)
                }
                helperText={
                  formik.touched.product_details &&
                  formik.errors.product_details
                }
              />
            </Grid>

            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="gst"
                name="gst"
                label="GST *"
                value={formik.values.gst}
                onChange={formik.handleChange}
                error={formik.touched.gst && Boolean(formik.errors.gst)}
                helperText={formik.touched.gst && formik.errors.gst}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="hsn_code"
                name="hsn_code"
                label="HSN Code *"
                value={formik.values.hsn_code}
                onChange={formik.handleChange}
                error={
                  formik.touched.hsn_code && Boolean(formik.errors.hsn_code)
                }
                helperText={formik.touched.hsn_code && formik.errors.hsn_code}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="return_window"
                name="return_window"
                label="Return Window (hrs) *"
                value={formik.values.return_window}
                onChange={formik.handleChange}
                error={
                  formik.touched.return_window &&
                  Boolean(formik.errors.return_window)
                }
                helperText={
                  formik.touched.return_window && formik.errors.return_window
                }
              />
            </Grid>
            <Grid item xs={12} mb={3} textAlign={"right"}>
              <Link to={"/unit"} target={"_blank"}>
                Add Units
              </Link>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="unit_id"
                name="unit_id"
                label="Unit *"
                select
                value={formik.values.unit_id}
                onChange={formik.handleChange}
                error={formik.touched.unit_id && Boolean(formik.errors.unit_id)}
                helperText={formik.touched.unit_id && formik.errors.unit_id}
              >
                {units.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name} ({unit.symbol})
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="unit_quantity"
                name="unit_quantity"
                label="Unit quantity"
                value={formik.values.unit_quantity}
                onChange={formik.handleChange}
                error={
                  formik.touched.unit_quantity &&
                  Boolean(formik.errors.unit_quantity)
                }
                helperText={
                  formik.touched.unit_quantity && formik.errors.unit_quantity
                }
              />
            </Grid>
            <Grid item xs={12} mb={3} textAlign={"right"}>
              <Link to={"/storage"} target={"_blank"}>
                Add Storage type
              </Link>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="storage_type_id"
                name="storage_type_id"
                label="Storage Type *"
                select
                value={formik.values.storage_type_id}
                onChange={formik.handleChange}
                error={
                  formik.touched.storage_type_id &&
                  Boolean(formik.errors.storage_type_id)
                }
                helperText={
                  formik.touched.storage_type_id &&
                  formik.errors.storage_type_id
                }
              >
                {storages.map((storage) => (
                  <MenuItem key={storage.id} value={storage.id}>
                    {storage.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="is_veg"
                name="is_veg"
                label="Is Vegetarian *"
                select
                value={formik.values.is_veg}
                onChange={formik.handleChange}
                error={formik.touched.is_veg && Boolean(formik.errors.is_veg)}
                helperText={formik.touched.is_veg && formik.errors.is_veg}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12} mb={3}>
              <TextField
                autoFocus={true}
                fullWidth
                id="priceFormula"
                name="priceFormula"
                label="Price Formula"
                type={"number"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={formik.values.priceFormula}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="price"
                name="price"
                label="MRP"
                type={"number"}
                value={formik.values.price}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="purchase_price"
                name="purchase_price"
                label="Purchase Price *"
                value={formik.values.purchase_price}
                onChange={handlePriceChange}
                error={
                  formik.touched.name && Boolean(formik.errors.purchase_price)
                }
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="min_order_quantity"
                name="min_order_quantity"
                label="Min Order Quantity *"
                value={formik.values.min_order_quantity}
                onChange={formik.handleChange}
                error={
                  formik.touched.name &&
                  Boolean(formik.errors.min_order_quantity)
                }
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="moq_sale_price"
                name="moq_sale_price"
                label="MOQ Sale Price *"
                value={formik.values.moq_sale_price}
                onChange={formik.handleChange}
                error={
                  formik.touched.name && Boolean(formik.errors.moq_sale_price)
                }
                helperText={
                  formik.touched.moq_sale_price && formik.errors.moq_sale_price
                }
              />
            </Grid>

            <Grid item xs={12} mb={3}>
              <TextField
                fullWidth
                id="discount"
                name="discount"
                label="Discount"
                value={formik.values.discount}
                onChange={formik.handleChange}
              />
            </Grid>
          </Grid>
          {/* Add other fields as needed */}
          <Grid item xs={12} mb={3}>
            <input
              type="file"
              id="images"
              name="images"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </Grid>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-images">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {productImages.map((image, index) => (
                    <Draggable
                      key={image.id}
                      draggableId={image.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <img
                            src={image.url}
                            alt={`Product`}
                            style={{
                              width: "100px",
                              height: "100px",
                              marginRight: "10px",
                            }}
                          />
                          <button
                            onClick={() => deleteImage(image.id)}
                            style={{ display: "block" }}
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button onClick={updateImageSequence}>Update Image Sequence</Button>
          <Grid item xs={12} mb={3}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {(productId || (id && id !== "new")) && (
        <Grid item xs={12} mb={5}>
          <ProductBulkAddForm
            productID={id && id !== "new" ? id : productId}
            purchasePrice={formik.values.moq_sale_price}
            bulks={bulks}
          />
        </Grid>
      )}
    </Container>
  );
}

export default ProductUploadForm;
