import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    TextField,
    Button,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const validationSchema = yup.object({
    first_name: yup.string("Enter the first name"),
    last_name: yup.string("Enter the last name"),
    email_id: yup
        .string("Enter the email ID")
        .email("Enter a valid email")
        .required("Email is required"),
    mobile_no: yup
        .string("Enter the mobile number")
        .required("Mobile number is required"),
    owner_id: yup.string("Select an owner"),
});

const UserForm = () => {
    const { id } = useParams();
    const [owners, setOwners] = useState([]);
    const [initialValues, setInitialValues] = useState({
        first_name: "",
        last_name: "",
        email_id: "",
        mobile_no: "",
        owner_id: "",
    });

    // Fetch owners
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/admin/user`)
            .then((response) => {
                setOwners(response.data.data.users);
            })
            .catch((error) => {
                console.error("Error fetching owners:", error);
                toast.error(`Error: ${error?.response?.data}`);
            });
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            const url = id && id !== "new"
                ? `${process.env.REACT_APP_API_URL}/admin/user/${id}`
                : `${process.env.REACT_APP_API_URL}/admin/user`;
            const method = id && id !== "new" ? "PUT" : "POST";

            axios({
                url,
                method,
                headers: {
                    "X-API-Key": "{{token}}",
                },
                data: values,
            })
                .then(() => {
                    toast.success(
                        id && id !== "new"
                            ? "User updated successfully!"
                            : "User created successfully!"
                    );
                })
                .catch((error) => {
                    toast.error(`Error: ${error?.response?.data}`);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        },
    });

    // Load user data for editing
    useEffect(() => {
        if (id && id !== "new") {
            axios.get(`${process.env.REACT_APP_API_URL}/admin/user/${id}`)
                .then((response) => {
                    setInitialValues(response.data.data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                    toast.error(`Error: ${error?.response?.data}`);
                });
        }
    }, [id]);

    return (
        <div>
            <h1>{id ? "Edit User" : "Create User"}</h1>
            <form onSubmit={formik.handleSubmit}>
                {/* User fields */}
                <Box mb={3}>
                    <TextField
                        fullWidth
                        id="first_name"
                        name="first_name"
                        label="First Name"
                        value={formik.values.first_name}
                        onChange={formik.handleChange}
                        error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                        helperText={formik.touched.first_name && formik.errors.first_name}
                    />
                </Box>

                <Box mb={3}>
                    <TextField
                        fullWidth
                        id="last_name"
                        name="last_name"
                        label="Last Name"
                        value={formik.values.last_name}
                        onChange={formik.handleChange}
                        error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                        helperText={formik.touched.last_name && formik.errors.last_name}
                    />
                </Box>

                <Box mb={3}>
                    <TextField
                        fullWidth
                        id="email_id"
                        name="email_id"
                        label="Email ID"
                        value={formik.values.email_id}
                        onChange={formik.handleChange}
                        error={formik.touched.email_id && Boolean(formik.errors.email_id)}
                        helperText={formik.touched.email_id && formik.errors.email_id}
                    />
                </Box>

                <Box mb={3}>
                    <TextField
                        fullWidth
                        id="mobile_no"
                        name="mobile_no"
                        label="Mobile Number"
                        value={formik.values.mobile_no}
                        onChange={formik.handleChange}
                        error={formik.touched.mobile_no && Boolean(formik.errors.mobile_no)}
                        helperText={formik.touched.mobile_no && formik.errors.mobile_no}
                    />
                </Box>

                {/* Owner Dropdown */}
                <Box mb={3}>
                    <FormControl fullWidth>
                        <InputLabel id="owner-label">Owner</InputLabel>
                        <Select
                            labelId="owner-label"
                            id="owner_id"
                            name="owner_id"
                            value={formik.values.owner_id}
                            onChange={formik.handleChange}
                        >
                            {owners.map((owner) => (
                                <MenuItem key={owner.id} value={owner.id}>
                                    {owner.first_name} {owner.last_name} ({owner.mobile_no})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    disabled={formik.isSubmitting}
                >
                    {id ? "Update User" : "Create User"}
                </Button>
            </form>
        </div>
    );
};

export default UserForm;
