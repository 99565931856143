import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Typography,
} from "@mui/material";

const Login = ({ onLogin }) => {
  const validateCredentials = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Required";
    } else if (values.username !== "admin") {
      errors.username = "Invalid username";
    }

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password !== "horeka@101") {
      errors.password = "Invalid password";
    }

    return errors;
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Card style={{ padding: "50px", maxWidth: "400px", width: "100%" }}>
        <CardContent>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Login
          </Typography>
          <Formik
            initialValues={{ username: "", password: "" }}
            validate={validateCredentials}
            onSubmit={(values, { setSubmitting }) => {
              if (values.username === "admin" && values.password === "horeka@101") {
                localStorage.setItem('isLoggedIn', 'true'); // Save login status to localStorage
                onLogin(true); // Update state in parent component
              } else {
                alert("Invalid credentials");
              }
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field type="text" name="username" placeholder="Username" d />
                <ErrorMessage name="username" component="div" />

                <Field type="password" name="password" placeholder="Password" />
                <ErrorMessage name="password" component="div" />
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 20 }}
                  disabled={isSubmitting}
                >
                  Login
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
