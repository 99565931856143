import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  outletName: yup.string().required("Outlet name is required"),
  address: yup.string().required("Address is required"),
  city: yup.string(),
  state: yup.string(),
  floorNumber: yup.string().min(3),
  country: yup.string(),
  lat: yup.string(),
  long: yup.string(),
  gstNumber: yup.string(),
  fssaiNumber: yup.string(),
  openingTime: yup.string(),
  user_id: yup.string("Select an owner"),
});

const OutletInfoForm = () => {
  const { id } = useParams();
  const [isEdit, setIsEdit] = useState(false);
  const [users, setUser] = useState([]);

  const initialValues = {
    outletName: "",
    address: "",
    city: "",
    state: "",
    floorNumber: "",
    country: "Indiaa",
    gstNumber: "",
    fssaiNumber: "",
    openingTime: "",
    lat: "",
    long: "",
    user_id: "",
  };

  const fetchUserList = async () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/user`)
      .then((response) => {
        setUser(response.data.data.users);
      })
      .catch((error) => {
        console.error("Error fetching owners:", error);
        toast.error(`Error: ${error?.response?.data}`);
      });
  };

  // Fetch owners
  useEffect(() => {
    fetchUserList();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);

      const outletData = {
        name: values.outletName,
        address: values.address,
        city: values.city,
        state: values.state,
        floor: values.floorNumber.toString(),
        country: values.country,
        gst_no: values.gstNumber,
        fssai_no: values.fssaiNumber,
        phone_number: values.phoneNumber,
        opening_time: values.openingTime,
        long: values.long,
        lat: values.lat,
        user_id: values.user_id,
      };

      const url = isEdit
        ? `${process.env.REACT_APP_API_URL}/admin/restaurant/${id}`
        : `${process.env.REACT_APP_API_URL}/admin/restaurant/`;
      const method = isEdit ? "PUT" : "POST";

      axios({ url, method, data: outletData })
        .then(() => {
          toast.success(`Outlet ${isEdit ? "updated" : "added"} successfully!`);
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error(`Error: ${error.response.data}`);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (id && id !== "new") {
      setIsEdit(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/restaurant/${id}`)
        .then((response) => {
          const outlet = response.data.data.restaurant;
          formik.setValues({
            outletName: outlet.name,
            address: outlet.address,
            city: outlet.city,
            state: outlet.state,
            floorNumber: outlet.floor, // Correctly mapped from API response
            country: outlet.country, // Correctly mapped from API response
            gstNumber: outlet.gst_no,
            fssaiNumber: outlet.fssai_no,
            openingTime: outlet.opening_time,
            lat: outlet.lat,
            long: outlet.long,
            user_id: outlet.user_id,
          });
        })
        .catch((error) => {
          console.error("Error fetching outlet:", error);
          toast.error(`Error: ${error.response.data}`);
        });
    }
  }, [id]);

  return (
    <div>
      <h1>{isEdit ? "Edit Outlet Information" : "Add Outlet Information"}</h1>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* Outlet Name */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="outletName"
                name="outletName"
                label="Outlet Name"
                value={formik.values.outletName}
                onChange={formik.handleChange}
                error={
                  formik.touched.outletName && Boolean(formik.errors.outletName)
                }
                helperText={
                  formik.touched.outletName && formik.errors.outletName
                }
              />
            </Box>

            {/* Address */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="address"
                name="address"
                label="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Box>

            {/* City */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="city"
                name="city"
                label="City"
                value={formik.values.city}
                onChange={formik.handleChange}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Box>

            {/* State */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="state"
                name="state"
                label="State"
                value={formik.values.state}
                onChange={formik.handleChange}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            </Box>

            {/* Floor Number */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="floorNumber"
                name="floorNumber"
                label="Floor Number"
                type="number"
                value={formik.values.floorNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.floorNumber &&
                  Boolean(formik.errors.floorNumber)
                }
                helperText={
                  formik.touched.floorNumber && formik.errors.floorNumber
                }
              />
            </Box>
            <Box mb={2}>
              <FormControl fullWidth>
                <InputLabel id="user-label">User</InputLabel>
                <Select
                  labelId="user-label"
                  id="user_id"
                  name="user_id"
                  value={formik.values.user_id}
                  onChange={formik.handleChange}
                >
                  {users.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.first_name} {user.last_name} ({user.mobile_no})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>

          <Grid item xs={6}>
            {/* Landmark */}

            {/* Country */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="country"
                name="country"
                label="Country"
                value={formik.values.country}
                onChange={formik.handleChange}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
              />
            </Box>

            <Box mb={2}>
              <TextField
                fullWidth
                id="lat"
                name="lat"
                label="lat"
                value={formik.values.lat}
                onChange={formik.handleChange}
                error={formik.touched.lat && Boolean(formik.errors.lat)}
                helperText={formik.touched.lat && formik.errors.lat}
              />
            </Box>

            {/* long */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="long"
                name="long"
                label="long"
                value={formik.values.long}
                onChange={formik.handleChange}
                error={formik.touched.long && Boolean(formik.errors.long)}
                helperText={formik.touched.long && formik.errors.long}
              />
            </Box>

            {/* GST Number */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="gstNumber"
                name="gstNumber"
                label="GST Number"
                value={formik.values.gstNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.gstNumber && Boolean(formik.errors.gstNumber)
                }
                helperText={formik.touched.gstNumber && formik.errors.gstNumber}
              />
            </Box>

            {/* FSSAI Number */}
            <Box mb={2}>
              <TextField
                fullWidth
                id="fssaiNumber"
                name="fssaiNumber"
                label="FSSAI Number"
                value={formik.values.fssaiNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.fssaiNumber &&
                  Boolean(formik.errors.fssaiNumber)
                }
                helperText={
                  formik.touched.fssaiNumber && formik.errors.fssaiNumber
                }
              />
            </Box>
            <Box mb={2}>
              <TextField
                fullWidth
                id="openingTime"
                name="openingTime"
                label="Opening Time"
                value={formik.values.openingTime}
                onChange={formik.handleChange}
                error={
                  formik.touched.openingTime &&
                  Boolean(formik.errors.openingTime)
                }
                helperText={
                  formik.touched.openingTime && formik.errors.openingTime
                }
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={3}>
          <Grid item xs={2}>
            <Button
              component={Link}
              to={`/users/new`}
              variant="contained"
              color="primary"
              target={"_blank"}
              fullWidth
            >
              Add User
            </Button>

          </Grid>
          <Grid item xs={2}>
            <Button variant="contained" color="primary" onClick={fetchUserList} fullWidth>
              Refresh User
            </Button>
          </Grid>
        </Grid>

        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          disabled={formik.isSubmitting}
        >
          {isEdit ? "Update Outlet" : "Add Outlet"}
        </Button>
      </form>
    </div>
  );
};

export default OutletInfoForm;
