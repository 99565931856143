import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Paper, IconButton,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import ConfirmDialog from "../Shared/ConfirmDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

function DragIndicatorIcon() {
  return null;
}

const ProductsList = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/products`,
        );
        setProducts(response.data.data.products);
      } catch (error) {
        console.error("Error fetching data: ", error);
        toast.error(`Error: ${error?.response?.data}`);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDeleteClick = (product) => {
    setSelectedProduct(product);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async (productId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/products/delete`,
        {
          id: productId,
        },
      );
      setProducts(products.filter((p) => p.id !== productId));
      setConfirmDialogOpen(false);
      toast.success("Successfully! Deleted a product");
    } catch (error) {
      console.error("Error deleting product: ", error);
      toast.error(`Error: ${error?.response?.data}`);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const items = Array.from(products);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProducts(items);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/admin/products`, {
        id: reorderedItem.id,
        sequence: result.destination.index,
      });
      toast.success("Product sequence updated successfully");
    } catch (error) {
      console.error("Error updating product sequence: ", error);
      toast.error(`Error: ${error?.response?.data}`);
    }
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error loading products: {error.message}</p>;
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <h1>Product List</h1>
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: "16px" }}
          component={Link}
          to="/product/new"
        >
          Add Product
        </Button>
        <Paper elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Drag</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Actions</TableCell>

              </TableRow>
            </TableHead>
            <Droppable droppableId="droppable-products">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {products.map((product, index) => (
                    <Draggable
                      key={product.id}
                      draggableId={product.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            backgroundColor: product.deleted
                              ? "#929292"
                              : "transparent",
                            opacity: product.deleted ? 0.3 : 1,
                            ...provided.draggableProps.style,
                          }}
                        >
                          <TableCell {...provided.dragHandleProps}>
                            <IconButton>
                              <DragIndicatorIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell>{product.id}</TableCell>
                          <TableCell>{product.name}</TableCell>
                          <TableCell>₹{product.price}</TableCell>
                          <TableCell>
                            {!product.deleted && (
                              <>
                                <Button
                                  component={Link}
                                  to={`/product/${product.id}`}
                                  variant="contained"
                                  color="primary"
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={() => handleDeleteClick(product)}
                                  style={{ marginLeft: "10px" }}
                                >
                                  Delete
                                </Button>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </Paper>
        <ConfirmDialog
          open={confirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={() => handleConfirmDelete(selectedProduct.id)}
          item={selectedProduct}
        />
      </div>
    </DragDropContext>
  );
};

export default ProductsList;
