import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

const Sidebar = () => {
  const [openProduct, setOpenProduct] = useState(false);
  const [openOutlet, setOpenOutlet] = useState(false);

  const handleClickProduct = () => {
    setOpenProduct(!openProduct);
  };

  const handleClickOutlet = () => {
    setOpenOutlet(!openOutlet);
  };

  return (
      <div style={{ width: '250px', height: '100vh' }}>
        <List>
          <ListItemButton onClick={handleClickProduct}>
            <ListItemText primary="Product" />
            {openProduct ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openProduct} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/categories">
                Categories
              </ListItem>
              <ListItem button component={Link} to="/subcategories">
                Subcategories
              </ListItem>
              <ListItem button component={Link} to="/product-type">
                Product Type
              </ListItem>
              <ListItem button component={Link} to="/products">
                Products List
              </ListItem>
            </List>
          </Collapse>
          <Divider />

          <ListItemButton onClick={handleClickOutlet}>
            <ListItemText primary="Outlet" />
            {openOutlet ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openOutlet} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/outlet">
                Outlet List
              </ListItem>
              {/* Additional Outlet sub-menu items can be added here */}
            </List>
          </Collapse>
          <Divider />
          <ListItem button component={Link} to="/order">
           Order List
          </ListItem>
        </List>
      </div>
  );
};

export default Sidebar;
