import React from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    MenuItem,
    Button,
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import axios from 'axios';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const OrderStatusSchema = Yup.object().shape({
    status: Yup.string().required('Status is required'),
});

const ChangeOrderStatusModal = ({ open, handleClose, orderId }) => {
    const handleSubmit = (values) => {
        axios.put(`${process.env.REACT_APP_API_URL}/admin/orders/update-status`, {
            order_id: orderId,
            status: values.status,
        })
            .then(() => {
                toast.success('Order status updated successfully');
                handleClose();
            })
            .catch((error) => {
                toast.error(`Error: ${error.response ? error.response.data : 'An error occurred'}`);
            });
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={modalStyle}>
                <Typography variant="h6" component="h2">
                    Change Order Status
                </Typography>
                <Formik
                    initialValues={{ status: '' }}
                    validationSchema={OrderStatusSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <Field
                                as={TextField}
                                name="status"
                                select
                                label="Order Status"
                                fullWidth
                                margin="normal"
                                helperText={touched.status && errors.status}
                            >
                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="accepted">Accepted</MenuItem>
                                <MenuItem value="rejected">Rejected</MenuItem>
                                <MenuItem value="delivered">Delivered</MenuItem>
                                <MenuItem value="canceled">Canceled</MenuItem>
                                <MenuItem value="on_the_way">On The Way</MenuItem>
                            </Field>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                style={{ marginTop: '16px' }}
                            >
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Modal>
    );
};

export default ChangeOrderStatusModal;
