import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ConfirmDialog from "../Shared/ConfirmDialog";

const SubCategoryList = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/sub-category`,
        );
        setSubCategories(response.data.data.subCategories);
      } catch (error) {
        console.error("Error fetching sub-categories:", error);
        toast.error(`Error : ${error?.response?.data}`);
      }
    };

    fetchSubCategories();
  }, []);

  const handleDeleteClick = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async (subCategoryId) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/sub-category/delete`,
        { id: subCategoryId },
      );
      setSubCategories(subCategories.filter((sc) => sc.id !== subCategoryId));
      setConfirmDialogOpen(false);
      toast.success("Sub-category successfully deleted");
    } catch (error) {
      console.error("Error deleting sub-category: ", error);
      toast.error(`Error : ${error?.response?.data}`);
    }
  };
  return (
    <div>
      <h1>Sub Category List</h1>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "16px" }}
        component={Link}
        to="/subcategory/new"
      >
        Add Category
      </Button>
      <Paper elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subCategories.map((subCategory) => (
              <TableRow
                key={subCategory.id}
                style={{
                  backgroundColor: subCategory.deleted
                    ? "#929292"
                    : "transparent",
                  opacity: subCategory.deleted ? 0.5 : 1,
                }}
              >
                <TableCell>
                  <Avatar src={subCategory.image.url} alt={subCategory.name} />
                </TableCell>
                <TableCell>{subCategory.name}</TableCell>
                <TableCell>
                  {!subCategory.deleted && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={`/subcategory/${subCategory.id}`}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleDeleteClick(subCategory)}
                        style={{ marginLeft: "10px" }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => handleConfirmDelete(selectedSubCategory.id)}
        item={selectedSubCategory}
      />
    </div>
  );
};

export default SubCategoryList;
