import React, { useEffect } from "react";
import { Formik, FieldArray, Form } from "formik";
import * as yup from "yup";
import { TextField, Button, Box, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import toast from "react-hot-toast";

const validationSchema = yup.object({
  items: yup.array().of(
    yup.object().shape({
      formula: yup.number().required("Formula is required"),
      quantity: yup.number().required("Quantity is required"),
    }),
  ),
});

const ProductBulkAddForm = ({ bulks, productID, purchasePrice, edit }) => {
  const calculateFormula = (bulk) => {
    // Example calculation - adjust according to your needs
    return bulk.price / purchasePrice;
  };
  const initialValues = {
    items:
      bulks && bulks.length > 0
        ? bulks.map((bulk) => ({
            id: bulk.id || null,
            formula: calculateFormula(bulk), // Implement this function based on your logic
            quantity: bulk.quantity,
            price: bulk.price,
          }))
        : [{ id: null, formula: 0.9, quantity: "", price: "" }],
  };

  const handleSubmit = (values, actions) => {
    actions.setSubmitting(true);

    const updateRequests = values.items
      .filter((item) => item.id)
      .map(({ formula, ...item }) => {
        // This is an existing item, so use PUT to update
        const payload = { ...item, product_id: productID };
        return axios.put(
          `${process.env.REACT_APP_API_URL}/admin/bulk`,
          payload,
        );
      });

    const addRequests = values.items
      .filter((item) => !item.id)
      .map(({ id, formula, ...item }) => {
        // This is a new item, so use POST to create
        const payload = { ...item, product_id: productID };
        return axios.post(
          `${process.env.REACT_APP_API_URL}/admin/bulk`,
          payload,
        );
      });

    Promise.all([...updateRequests, ...addRequests])
      .then((responses) => {
        // Handle the success responses
        toast.success("Items processed successfully");
      })
      .catch((error) => {
        // Handle errors
        toast.error(`Error: ${error?.response?.data}`);
      })
      .finally(() => {
        actions.setSubmitting(false);
        actions.resetForm();
      });
  };

  const handleFormulaChange = (e, index, setFieldValue) => {
    const newFormula = e.target.value;
    const calculatedPrice = purchasePrice * newFormula;
    setFieldValue(`items[${index}].formula`, newFormula);
    setFieldValue(`items[${index}].price`, calculatedPrice.toFixed(2));
  };

  const handleDelete = (id) => {
    if (id) {
      const deleteURL = `${process.env.REACT_APP_API_URL}/admin/bulk/${id}`;
      axios
        .delete(deleteURL)
        .then(() => {
          toast.success("Item deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting item:", error);
          toast.error(`Error: ${error?.response?.data}`);
        });
    }
  };

  return (
    <div>
      <h1>Add Bulk Product Prices and Quantities</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form>
            <FieldArray name="items">
              {({ push, remove }) => (
                <>
                  {formik.values.items.map((item, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={2}>
                      <TextField
                        fullWidth
                        name={`items[${index}].formula`}
                        label="Formula"
                        type="number"
                        value={item.formula}
                        onChange={(e) =>
                          handleFormulaChange(e, index, formik.setFieldValue)
                        }
                        error={
                          formik.touched.items?.[index]?.formula &&
                          Boolean(formik.errors.items?.[index]?.formula)
                        }
                        helperText={
                          formik.touched.items?.[index]?.formula &&
                          formik.errors.items?.[index]?.formula
                        }
                        sx={{ mr: 2 }}
                      />
                      <TextField
                        fullWidth
                        name={`items[${index}].quantity`}
                        label="Quantity"
                        type="number"
                        value={item.quantity}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.items?.[index]?.quantity &&
                          Boolean(formik.errors.items?.[index]?.quantity)
                        }
                        helperText={
                          formik.touched.items?.[index]?.quantity &&
                          formik.errors.items?.[index]?.quantity
                        }
                      />
                      <TextField
                        fullWidth
                        name={`items[${index}].price`}
                        label="Price"
                        type="number"
                        value={item.price}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{ ml: 2, mr: 2 }}
                      />
                      <IconButton
                        onClick={() => {
                          handleDelete(item.id);
                          remove(index);
                        }}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                      {formik.values.items.length - 1 === index && (
                        <IconButton
                          onClick={() =>
                            push({ formula: 1.4, quantity: "", price: "" })
                          }
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </FieldArray>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ProductBulkAddForm;
