import React, { useState, useEffect } from "react";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const TransactionList = () => {
    const [transactions, setTransactions] = useState([]);
    const { id } = useParams(); // Assuming you're using react-router

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/admin/restaurant/transactions/${id}`)
            .then((response) => {
                setTransactions(response.data.data.transactions);
            })
            .catch((error) => {
                console.error("Error fetching transactions:", error);
                toast.error(`Error: ${error?.response?.data}`);
            });
    }, [id]);

    return (
        <div>
            <h1>Transaction List</h1>

            <Paper elevation={3} style={{ marginTop: "16px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Order ID</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Transaction Type</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.map((transaction) => (
                            <TableRow key={transaction.id}>
                                <TableCell>{transaction.id}</TableCell>
                                <TableCell>{transaction.order_id}</TableCell>
                                <TableCell>{transaction.amount}</TableCell>
                                <TableCell>{transaction.transaction_type}</TableCell>
                                <TableCell>{transaction.status === 1 ? "Active" : "Inactive"}</TableCell>
                                <TableCell>{new Date(transaction.created_at).toLocaleString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
};

export default TransactionList;
