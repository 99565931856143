import React, { useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";

const CreditModal = ({ open, handleClose, restaurantId }) => {
  const [amount, setAmount] = useState("");

  const onSubmit = () => {
    if (!amount) {
      alert("Please enter a valid amount");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/admin/restaurant/add-credit-amount`,
        {
          restaurant_id: restaurantId,
          amount: parseFloat(amount),
        },
      )
      .then(() => {
        toast.success("Credit added successfully");
        handleClose();
      })
      .catch((error) => {
        toast.error(
          `Error: ${
            error.response ? error.response.data : "An error occurred"
          }`,
        );
      });

    setAmount("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Add Credit to Outlet
        </Typography>
        <TextField
          fullWidth
          label="Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          margin="normal"
          type="number"
          inputProps={{ min: 0 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          style={{ marginTop: "20px" }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default CreditModal;
